import { useGlobalState } from "@app/contexts"
import { normalizeURL } from "@app/utils"
import { getSocialNetworksList } from "./utils/get-social-networks-list"

import { Facebook, Insta, Vk, Telegram, Vimeo, Youtube, WhatsApp } from "@app/ui/icons"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

import { SOCIAL_NETWORKS, ESocialNetworks } from "./constants"

import styles from "./social-networks.module.scss"

const socialIcons: Record<ESocialNetworks, JSX.Element> = {
  [ESocialNetworks.Instagram]: (
    <IconWrapper light={<Insta width={26} height={26} />} dark={<Insta width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Facebook]: (
    <IconWrapper light={<Facebook width={26} height={26} />} dark={<Facebook width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Vkontakte]: (
    <IconWrapper light={<Vk width={26} height={26} />} dark={<Vk width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Telegram]: (
    <IconWrapper light={<Telegram width={26} height={26} />} dark={<Telegram width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Vimeo]: (
    <IconWrapper light={<Vimeo width={26} height={26} />} dark={<Vimeo width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Youtube]: (
    <IconWrapper light={<Youtube width={26} height={26} />} dark={<Youtube width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.WhatsApp]: (
    <IconWrapper
      light={<WhatsApp width={26} height={26} />}
      dark={<WhatsApp width={26} height={26} fill="#fff" stroke="#fff" />}
    />
  ),
}

const SocialNetworks = () => {
  const userProile = useGlobalState()
  const items = getSocialNetworksList(SOCIAL_NETWORKS, userProile, socialIcons)

  return (
    <ul className={styles["social-networks"]}>
      {items.map((item) => {
        return (
          <li key={item.value} className={styles["social-network"]}>
            <a
              className={styles["social-link"]}
              href={normalizeURL(item.value, item.type)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export { SocialNetworks }
